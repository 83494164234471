import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const IndexPageTemplate = ({ release }) => (
  <div className="homepage-release">
    <img src={release.frontmatter.featuredimage.childImageSharp.fluid.src} />
    <div className="text">
      <h1>{release.frontmatter.title}</h1>
      <HTMLContent className="content" content={release.html} />
      <div className="links">
        {release.frontmatter.spotify && (
          <span>
            <a href={release.frontmatter.spotify}>spotify</a> |{" "}
          </span>
        )}
        {release.frontmatter.applemusic && (
          <span>
            <a href={release.frontmatter.applemusic}>apple music</a> |{" "}
          </span>
        )}
        {release.frontmatter.soundcloud && (
          <span>
            <a href={release.frontmatter.soundcloud}>soundcloud</a>
          </span>
        )}
      </div>
    </div>
  </div>
);

const IndexPage = ({ data }) => {
  if (data)
    return (
      <Layout>
        <IndexPageTemplate release={data.allMarkdownRemark.edges[0].node} />
      </Layout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "release" } } }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            spotify
            applemusic
            soundcloud
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
